import * as React from 'react';
import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogContent,
    DialogBody,
    DialogActions,
    Button,
    Input,
    Label,
    makeStyles,
    Tooltip,
    shorthands,
    Textarea,
} from '@fluentui/react-components';
import { Bookmark16Regular } from '@fluentui/react-icons';
import { IChatMessage } from '../../../libs/models/ChatMessage';
import useLocalStorageState from 'use-local-storage-state';
import { Preset } from '../../../libs/services/SettingsService';
import { useState } from 'react';
import { BotResponsePrompt } from '../../../libs/models/BotResponsePrompt';

const useClasses = makeStyles({
    button: {
        ...shorthands.padding(0),
        ...shorthands.margin(0),
        minWidth: 'auto',
        marginLeft: 'auto', // align to right
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '10px',
    },
});

interface SaveAsPresetProps {
    message: IChatMessage;
}

export const SaveAsPreset: React.FC<SaveAsPresetProps> = ({ message }) => {
    const classes = useClasses();

    const [userPresets, setUserPresets] = useLocalStorageState<Preset[]>('userPresets', { defaultValue: [] });

    const [open, setOpen] = useState(false);

    const prompt = JSON.parse(message.prompt ?? '{}') as BotResponsePrompt | undefined;
    const userIntent = (prompt?.userIntent ?? '').replace(/^user intent:\s+/i, '');

    const [label, setLabel] = useState<string>('');
    const [content, setContent] = useState<string>(userIntent);

    const handleSubmit = (ev: React.FormEvent) => {
        ev.preventDefault();

        setUserPresets([...userPresets, { label, content } as Preset]);

        setOpen(false);
    };
    return (
        <Dialog
            open={open}
            onOpenChange={(_, { open }) => {
                setOpen(open);
            }}
            modalType="non-modal"
        >
            <DialogTrigger disableButtonEnhancement>
                <Tooltip content="Als Vorlage merken" relationship="label">
                    <Button className={classes.button} icon={<Bookmark16Regular />} appearance="transparent" />
                </Tooltip>
            </DialogTrigger>
            <DialogSurface aria-describedby={undefined}>
                <form onSubmit={handleSubmit} autoComplete="off">
                    <DialogBody>
                        <DialogTitle>Anfrage als Vorlage merken</DialogTitle>
                        <DialogContent className={classes.content}>
                            <div>
                                Die Vorlage wird im lokalen Speicher des Browsers hinterlegt. Wird dieser entfernt,
                                gehen auch die gespeicherten Vorlagen verloren.
                            </div>
                            <Label required htmlFor={'label'}>
                                Label
                            </Label>
                            <Input
                                required
                                type="text"
                                id={'label'}
                                value={label}
                                onChange={(_, { value }) => {
                                    setLabel(value);
                                }}
                            />
                            <Label required htmlFor={'content'}>
                                Intent
                            </Label>
                            <Textarea
                                required
                                id={'content'}
                                resize="vertical"
                                value={content}
                                onChange={(_, { value }) => {
                                    setContent(value);
                                }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <DialogTrigger disableButtonEnhancement>
                                <Button appearance="secondary">Abbrechen</Button>
                            </DialogTrigger>
                            <Button type="submit" appearance="primary">
                                Merken
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </form>
            </DialogSurface>
        </Dialog>
    );
};
